
import { defineComponent } from "vue";
import AvaliacaoColumn from "@/util/table-columns/AvaliacaoColumn";
import useList from "@/modules/useList";
import store from "@/store";

export default defineComponent({
  setup() {
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
    } = useList(
      store.getters.divisaoId
        ? "divisoes/avaliacoes/" + store.getters.divisaoId
        : "avaliacoes",
      AvaliacaoColumn
    );

    return {
      rows,
      loading,
      filter,
      columns,
      list,
      modalForm,
      openModalForm,
      formatData,
    };
  },
});
